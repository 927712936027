import React from 'react';
//import logo from './logo.svg';
import './App.scss';
import { Outlet } from "react-router";
import Navbar from "./Components/Navbar/Navbar";
import { UserProvider } from "./Context/useAuth";
import Toast from './Components/Toast/Toast';
import Footer from './Components/Footer/Footer';
import { OrderProvider } from './Context/useOrder';
import { InterceptorProvider } from './Context/useInterceptor';

function App() {
    return (
    <>
      <UserProvider>
        <InterceptorProvider>
          <OrderProvider>
            <main role="main" className="container-fluid">
              <Navbar />
              <Outlet />
            </main>
            <Footer />
          </OrderProvider>
        </InterceptorProvider>
      </UserProvider>
      <Toast />
    </>
  );
}
export default App;
