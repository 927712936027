import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ProtectedRoute from "./ProtectedRoute";
import MenuPage from "../Pages/MenuPage/MenuPage";
import OrderPage from "../Pages/OrderPage/OrderPage";
import OrderListPage from "../Pages/OrderListPage/OrderListPage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import LogoutPage from "../Pages/LogoutPage/LogoutPage";
import PutAwayPage from "../Pages/PutAwayPage/PutAwayPage";
import QaListPage from "../Pages/QaListPage/QaListPage";
import WrapListPage from "../Pages/WrapListPage/WrapListPage";
import DispatchListPage from "../Pages/DispatchListPage/DispatchListPage";

export const router = createBrowserRouter([
{
    path: "/",
    element: <App />,
    children: [
        {
            path: "", element: (
                <ProtectedRoute>
                    <MenuPage />
                </ProtectedRoute>
            ),
            handle: 'Menu'
        },
        { 
            path: "menu", element: (
                <ProtectedRoute>
                    <MenuPage />
                </ProtectedRoute>
            ), 
            handle: 'Menu'
        },
        { 
            path: "order", element: (
                <ProtectedRoute>
                    <OrderPage />
                </ProtectedRoute>
            ),
            handle: 'Order'
        },
        { 
            path: "orders", element: (
                <ProtectedRoute checkLoginAttribute="allow_pick" >
                    <OrderListPage />
                </ProtectedRoute>
            ),
            handle: 'Pick'
        },
        { 
            path: "putaway", element: (
                <ProtectedRoute checkLoginAttribute="allow_putaway">
                    <PutAwayPage />
                </ProtectedRoute>
            ),
            handle: 'Put Away'
        },
        { 
            path: "move", element: (
                <ProtectedRoute checkLoginAttribute="allow_move">
                    <PutAwayPage />
                </ProtectedRoute>
            ),
            handle: 'BIN Move'
        },
        { 
            path: "qa", element: (
                <ProtectedRoute checkLoginAttribute="allow_qa">
                    <QaListPage />
                </ProtectedRoute>
            ),
            handle: 'QA'
        },
        { 
            path: "wrap", element: (
                <ProtectedRoute checkLoginAttribute="allow_wrap">
                    <WrapListPage />
                </ProtectedRoute>
            ),
            handle: 'Wrap'
        },
        { 
            path: "returnstock", element: (
                <ProtectedRoute checkLoginAttribute="allow_return">
                    <PutAwayPage />
                </ProtectedRoute>
            ),
            handle: 'Return To Stock'
        },
        { 
            path: "dispatch", element: (
                <ProtectedRoute checkLoginAttribute="allow_dispatch">
                    <DispatchListPage />
                </ProtectedRoute>
            ),
            handle: 'Dispatch'
        },
        { path: "login", element: <LoginPage /> },
        { path: "logout", element: <LogoutPage /> },
    ]
  }
]);
