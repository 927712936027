import React, { useEffect } from 'react'
import "./MenuPage.scss";
import { useNavigate } from 'react-router';
import { useOrder } from '../../Context/useOrder';
import { useAuth } from '../../Context/useAuth';

interface Props {}

const MenuPage = (props: Props) => {
    const navigate = useNavigate();
    const { setCurrentOrder} = useOrder();
    const { getUser } = useAuth();
        
    useEffect(() => {
        setCurrentOrder(null);
    }, [setCurrentOrder]);
           
    const onNavigate = (routeName: string) => {
        if (routeName) {
          navigate(routeName);
        }
    };

    return (
        <div className="container-limited-width menu-page">
            <div className="row">
                {(getUser()?.attributes?.allow_pick === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/orders')}>
                            <i className="fa fa-barcode fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">Pick</div>
                        </div>
                    </div>
                }
                {(getUser()?.attributes?.allow_putaway === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/putaway')}>
                            <i className="fa fa-archive fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">Put Away</div>
                        </div>
                    </div>
                }
                {(getUser()?.attributes?.allow_move === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/move')}>
                            <i className="fa fa-arrows fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">BIN Move</div>
                        </div>
                    </div>
                }               
                {(getUser()?.attributes?.allow_return === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/returnstock')}>
                            <i className="fa fa-stack-exchange fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">Return To Stock</div>
                        </div>
                    </div>
                }
                 {(getUser()?.attributes?.allow_wrap === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/wrap')}>
                            <i className="fa fa-gift fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">Wrap</div>
                        </div>
                    </div>
                }
                {(getUser()?.attributes?.allow_qa === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/qa')}>
                            <i className="fa fa-question fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">QA</div>
                        </div>
                    </div>
                }
                 {(getUser()?.attributes?.allow_dispatch === 'true') &&
                    <div className="col-6">            
                        <div className="box-part text-center d-flex flex-column justify-content-around" onClick={() => onNavigate('/dispatch')}>
                            <i className="fa fa-paper-plane-o fa-5x" aria-hidden="true"></i>
                            <div className="title flex-row">Dispatch</div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default MenuPage;