import { NavLink, useLocation, useMatches, useNavigate } from "react-router-dom";
//import logo from "../../logo.svg";
import "./Navbar.scss";
import { useAuth } from "../../Context/useAuth";
import { useEffect, useState } from "react";
import Moment from 'react-moment';
import { useOrder } from "../../Context/useOrder";
const ENV_APP_VERSION = process.env.REACT_APP_VERSION;

interface Props {}

const Navbar = (props: Props) => {
  const { isAuthenticated } = useAuth();
  const [ navbarCollapsed, setNavbarCollapsed ] = useState(true);
  const [ showNavbar, setShowNavbar ] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname.toLowerCase();
  const { getCurrentOrder } = useOrder();

  const matches = useMatches();
  const { handle } = matches[matches.length - 1]
  const pageTitle: string = handle?.toString() || '';
  
  useEffect(() => {
    const toShowNavbar = (!currentRoute || (currentRoute && currentRoute.indexOf('/login') === -1 && currentRoute !== '/logout'));
    setShowNavbar(!!toShowNavbar);
  }, [currentRoute, location]);
  const onLogo = () => {
    navigate('/menu');
  };
  const onNavbarToggle = () => {
    setNavbarCollapsed(!navbarCollapsed);
  }
  const collapseButtonClass = () => {
    let cssClass = 'navbar-toggler';
    if (navbarCollapsed) {
        cssClass += ' collapsed';
    }
    return cssClass;
  }
  const collapseContextClass = () => {
    let cssClass= 'navbar-collapse collapse';
    if (!navbarCollapsed) {
        cssClass += ' show';
    }
    return cssClass;
  }
  const order = getCurrentOrder();
  return (
    <>
        <div className="ios-bar"></div>
        {showNavbar ? 
            (
                <>
                    <nav className="navbar navbar-expand-lg fixed-top bg-dark-subtle md-4 container-limited-width d-flex flex-row justify-content-between">
                        <a className="navbar-brand" onClick={() => onLogo()}>
                            <img src="assets/images/logo-96x96.png" width="30" height="30" className="d-inline-block" alt="" />
                        </a>
                        {isAuthenticated() && (
                            <>
                                {order && order.transRefCode && order.transDueDate ? (
                                    <div className="edit-row">
                                        <div className="edit-line mx-1">
                                            <div>
                                                {order.transRefCode}
                                            </div>
                                        </div>
                                        <div className="edit-line mx-1">
                                            <div>
                                                Due: <Moment date={order.transDueDate} format="ddd DD/MM/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <h3>{pageTitle}</h3>
                                )}
                            </>        
                        )}
                       
                        {/* collapsible */}
                        <button className={collapseButtonClass()} 
                            data-bs-toggle="collapse" 
                            data-bs-target="#navbarContent" 
                            aria-controls="navbarContent" 
                            aria-expanded={!navbarCollapsed} 
                            aria-label="Toggle navigation" 
                            type="button" 
                            onClick={() => onNavbarToggle()}>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={collapseContextClass()} id="navbarContent">
                            <ul className="navbar-nav mr-auto">
                                {isAuthenticated() ? (
                                    <>
                                        <li className="nav-item nav-item d-flex flex-column justify-content-center">
                                            <NavLink className="d-flex flex-row align-items-center nav-link btn btn-link" title="Logout" to='/logout'>
                                                <i className="fa fa-sign-out footer-icon" aria-hidden="true"></i>
                                                <span>Logout</span>
                                            </NavLink>
                                        </li>
                                    </>
                                ) : ("")}
                            </ul>
                            <div>[v{ENV_APP_VERSION}]</div>
                        </div>
                        {/* end collapsible */}
                    </nav>
                </>
            )
            : 
            ("")
        }
        
    </>
  );
};

export default Navbar;
