import { useCallback, useEffect } from 'react';

export const useKeyDown = (callback: any, keys: string[]) => {
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (keys && keys.length > 0 && event.key) {
        const wasAnyKeyPressed = keys.some((key) => event.key.toLowerCase() === key.toLowerCase());
        if (wasAnyKeyPressed) {
          event.preventDefault();
          callback(event);
        }
    } else {
        callback(event);
    }
  }, [callback, keys]);
  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);
};

//usage example
// useKeyDown((event: any) => {
//     onKeyPressed(event);
// }, ['tab', 'enter']);
