import { createContext, useState } from "react";
import React from "react";
import axios from "axios";
import { useAuth } from "./useAuth";

type InterceptorContextType = {
  getIsPendingStatus: () => boolean;
  setIsPendingStatus: (isPending: boolean) => void;
};

type Props = { children: React.ReactNode };

const InterceptorContext = createContext<InterceptorContextType>({} as InterceptorContextType);

export const InterceptorProvider = ({ children }: Props) => {
  const [isPendingState, setIsPendingState] = useState<boolean>(false);
  let _pendingRequests = 0;
  const _filteredUrlPatterns: RegExp[] = [/refresh|scanitem|barcode/];
  const { changeUserToken } = useAuth();

  const getIsPendingStatus = (): boolean => {
    return isPendingState;
  };
  const setIsPendingStatus = (isPending: boolean): void => {
    setIsPendingState(isPending);
  };
  
  const shouldBypass = (url: string): boolean => {
      return _filteredUrlPatterns.some(e => {
          return e.test(url);
      });
  };
  // Add a request interceptor
  axios.interceptors.request.use((config) => {
      // Do something before request is sent
      const _shouldBypass = config?.url ? shouldBypass(config.url) : true;
      if (!_shouldBypass) {
          _pendingRequests++;

          if (1 === _pendingRequests) {
              setIsPendingStatus(true);
          }
      }

      return config;
  }, (error) => {
      // Do something with request error
      const _shouldBypass = error?.config?.url ? shouldBypass(error?.config?.url) : true;
      if (!_shouldBypass) {
          _pendingRequests--;
          if (0 === _pendingRequests) {
              setIsPendingStatus(false);
          }
      }
      return Promise.reject(error);
  });

  // Add a response interceptor
  axios.interceptors.response.use((response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const _shouldBypass = response?.config?.url ? shouldBypass(response?.config?.url) : true;
      if (!_shouldBypass) {
          _pendingRequests--;
          if (0 === _pendingRequests) {
              setIsPendingStatus(false);
          }
      }
      
      if ((response.headers.has as any)('Set-Authorization')) {
        const newTokenInHeader = (response.headers.get as any)('Set-Authorization');
        changeUserToken(newTokenInHeader);
      }
      return response;
  }, (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const _shouldBypass = error?.config?.url ? shouldBypass(error?.config?.url) : true;
      if (!_shouldBypass) {
          _pendingRequests--;
          if (0 === _pendingRequests) {
              setIsPendingStatus(false);
          }
      }
      return Promise.reject(error);
  });
  return (
    <InterceptorContext.Provider
      value={{ getIsPendingStatus, setIsPendingStatus }}
    >
      <>
        <div className={isPendingState ? '' : 'hidden'}>
          <div className="loading">
            <div className="spinner"></div>
          </div>
        </div>
        {children}
      </>
    </InterceptorContext.Provider>
  );
};

export const useInterceptor = () => React.useContext(InterceptorContext);
