import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import { useAuth } from '../../Context/useAuth';
import { useOrder } from '../../Context/useOrder';

function LogoutPage() {
    const { logout, isAuthenticated } = useAuth();
    const { setCurrentOrder} = useOrder();
    useEffect(() => {
        if (isAuthenticated()) { 
            logout();
            setCurrentOrder(null);
        }
    }, [logout, isAuthenticated, setCurrentOrder]);
    
    return (
        <div className="row mb-2">
            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 mb-2 mt-4">
            <h4 className="text-center">
                You have been logged out<br/>
            </h4>
            <div className="text-center mt-5">
                <NavLink className="btn btn-outline-secondary" title="Login" to='/Login'>Login</NavLink>
            </div>
            </div>
        </div>
    );
}

export default LogoutPage;