import axios from "axios";
import { AuthenticateRequest } from "../../Models/AuthenticateRequest";
import { UserInfo } from "../../Models/UserInfo";
import { ApiError } from "../../Models/ApiError";
import { HandleError } from "../../Helpers/ErrorHandler";

const API_URL = process.env.REACT_APP_API_URL;

export const loginAPI = async (model: AuthenticateRequest) : Promise<UserInfo> => {
  return new Promise<UserInfo>((resolve, reject) => {
    axios.post<UserInfo>(`${API_URL}auth/authenticate`, model)
    .then(resp => {
      return resolve(resp.data);
    })
    .catch((error: any) => {
      const apiErr: ApiError = HandleError(error);
      return reject(apiErr);
    });
  });
};
export const refreshTokenAPI = async () : Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    axios.get<string>(`${API_URL}auth/user`)
      .then(resp => {
        return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr : ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
}
