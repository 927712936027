import { useEffect, useState } from 'react'
import "./DispatchListPage.scss";
import * as orderService from "../../Services/Http/OrderService";
import { OrderList } from '../../Models/OrderList';
import { ApiError } from '../../Models/ApiError';
import { ChangeStatusRequest } from '../../Models/ChangeStatusRequest';
import { ChangeStatusResponse } from '../../Models/ChangeStatusResponse';
import OrderListComponent from '../../Components/OrderListComponent/OrderListComponent';
import { Order } from '../../Models/Order';


const DispatchListPage = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    
    useEffect(() => {
        bindGrid();
    }, []);

    const bindGrid = () => {
        orderService.getExistingOrdersForDispatchAPI()
        .then((resp: OrderList) => {
            if (resp) {
                setOrders([...resp.result!]);
            } else {
                setOrders([]);
            }
        })
        .catch((err: ApiError) => {
            //toast.error(err.message); //error already handled in error handler
        });
    };
    const handleSetModalResult = (changeStatusRequest: ChangeStatusRequest) : Promise<ChangeStatusResponse> => {
        return new Promise<ChangeStatusResponse>((resolve, reject) => {
            orderService.setOrderDispatchStatusAPI(changeStatusRequest)
            .then((resp: ChangeStatusResponse) => {
                bindGrid();
                resolve(resp);
            })
            .catch((err: ApiError) => {
                bindGrid();
                reject(err);
            });
        });
    }
    return (
        <OrderListComponent 
            orderArr={orders}
            modalTitle="Have the goods been dispatched?"
            onSetModalResult={handleSetModalResult} 
        />
    );
}

export default DispatchListPage;