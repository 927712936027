import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../Context/useAuth";
import { useForm } from "react-hook-form";
import { UserInfo } from "../../Models/UserInfo";
import { ApiError } from "../../Models/ApiError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useOrder } from "../../Context/useOrder";
import { useKeyDown } from "../../Hooks/useKeyDown";

const ENV_VERSION = process.env.REACT_APP_VERSION;
const ENABLE_QR_LOGIN = false;

type Props = {};

type LoginFormsInputs = {
  userName: string;
  password: string;
};

const validation = Yup.object().shape({
  userName: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = (props: Props) => {
    const { login, logout, getUser } = useAuth();
    const { setCurrentOrder} = useOrder();
    useEffect(() => {
        // reset login status
        if (getUser()) {
            logout();
            setCurrentOrder(null);
        }
    }, []);
    
    if (ENABLE_QR_LOGIN) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useKeyDown((event: any) => {
            onKeyPressed(event);
        }, []);
    }

    const onKeyPressed = (event: any)  => {
        if (event.target && !(event.target instanceof HTMLInputElement)){
            setFocus('userName');
        }
    };

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setFocus,
    } = useForm<LoginFormsInputs>({ resolver: yupResolver(validation) });
    const navigate = useNavigate();
    const handleLogin = (form: LoginFormsInputs) => {
        setSubmitted(true);
        setSubmitDisabled(true);
        login(form.userName, form.password)
            .then((user: UserInfo | null) => {
                if (user) {
                    navigate('/menu');
                } else {
                    toast.error('Wrong username or password');
                }
                setSubmitDisabled(false);
                setSubmitted(false);
            })
            .catch((err: ApiError) => {
                setSubmitDisabled(false);
                setSubmitted(false);
                if (err?.code?.toUpperCase() !== "ERR_NETWORK") {
                    toast.error('Wrong username or password');
                }
            });
    };
    return (
        <>
            <div className="row mb-2">
                <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-12 mb-2">
                    <form onSubmit={handleSubmit(handleLogin)} className="form-signin text-center d-grid gap-2">
                        <div className="d-flex justify-content-center">
                            <img className="big-logo" src="assets/images/login-page-logo.png" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="username" className="visually-hidden">Username</label>
                            <input
                                type="text"
                                id="username"
                                className={submitted && errors.userName ? 'form-control is-invalid' : 'form-control'}
                                placeholder="Username"
                                autoComplete="off"
                                {...register("userName")}
                            />
                            {submitted && errors.userName && (
                                <div className="invalid-feedback">
                                    <div>{errors.userName.message}</div>
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="password" className="visually-hidden">Password</label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Password"
                                className={submitted && errors.password ? 'form-control is-invalid' : 'form-control'}
                                autoComplete="off"
                                {...register("password")}
                                />
                            {submitted && errors.password && (
                                <div className="invalid-feedback">
                                    <div>{errors.password.message}</div>
                                </div>
                            )}
                        </div>
                        <div className="form-group d-grid">
                            <button disabled={submitDisabled} className="btn btn-lg btn-outline-primary btn-block" type="submit">Login</button>
                            {
                                submitDisabled && (
                                    <p className="text-center">Please wait...</p>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
            <footer className="provider-logo-footer mt-5 fixed-bottom">
                <div className="d-flex flex-column justify-content-center text-center">
                    <div className="provider-logo-text">Powered By</div>
                    <img className="provider-logo-small" src="assets/images/login-page-powered-by-logo.png" alt="eSuite" />
                </div>
                <div className="version">v{ENV_VERSION}</div>
            </footer>
        </>
    );
};

export default LoginPage;
