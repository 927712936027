import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./OrderPage.scss";
import * as itemService from "../../Services/Http/ItemService";
import * as orderService from "../../Services/Http/OrderService";
import { useOrder } from '../../Context/useOrder';
//import { useKeyDown } from '../../Hooks/useKeyDown';
import { Product } from '../../Models/Product';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { PickItemRequest } from '../../Models/PickItemRequest';
import { ScanItemResponse } from '../../Models/ScanItemResponse';
import { PickCompleteRequest } from '../../Models/PickCompleteRequest';
import { PickCompleteResponse } from '../../Models/PickCompleteResponse';
import { PickItemResponse } from '../../Models/PicktemResponse';
import { useAuth } from '../../Context/useAuth';
import { Button, Modal } from 'react-bootstrap';
import { Order } from "../../Models/Order";
import { LockUnlockRequest } from '../../Models/LockUnlockRequest';
import { LockUnlockResponse } from '../../Models/LockUnlockResponse';
import { GenericResponse } from '../../Models/GenericResponse';

interface Props {}

const successSound = new Audio();
successSound.src = '/assets/sounds/success.mp3';
successSound.load();

const errorSound = new Audio();
errorSound.src = '/assets/sounds/error.mp3';
errorSound.load();

const questionSound = new Audio();
questionSound.src = '/assets/sounds/question.mp3';
questionSound.load();

type ScanObject = {
    binNo?: string;
    binCode?: string;
    locationNo?: string;
    serialNo?: string;
    stockCode?: string;
    stockNo?: string;
    stockDesc?: string;
    scanFactor?: string;
    status?: number; //Error = 0,Success = 1,BarcodeNotFound = 2,BinCodeNeeded = 3,StockCodeNotFoundInBin = 4,BinNotFound = 5,BinFoundWithWrongStock = 6
    scanResponseStatus?: ScanResponseStatusType,
    transNo?: string;
    companyNo?: string;
    detailNo?: number;
    pickQty?: number;
};

type ScanResponseStatusType = 'error' | 'success' | 'scanbin';
const scanBarcodePlaceholderMessage = 'Scan barcode';
const scanBarcodeOrQryPlaceholderMessage = 'Scan barcode or enter quantity';

const OrderPage = (props: Props) => {
    const { getCurrentOrder, addToCart } = useOrder();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const searchBoxInputRef = useRef<HTMLInputElement>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [palletsUsed, setPalletsUsed] = useState<string>('');
    const [scanBarcodePlaceholder, setScanBarcodePlaceholder] = useState<string>(scanBarcodePlaceholderMessage);
    const scanObject = useRef<ScanObject | null>(null);
    const locked = useRef<boolean>(false);
    const navigate = useNavigate();
    const { getUser } = useAuth();
    
    const completeOrder = useCallback((order: Order, setAmountOfPalletsUsed?: number): void => {
        let currOrder: Order | null = order;
        if (!order) {
            currOrder = getCurrentOrder();
        }
        if (!currOrder || !(currOrder!.cartLines)) {
          return;
        }

        //all lines are complete
        const pickCompleteRequest : PickCompleteRequest = {
           companyNo: order.companyNo,
           transNo: order.transNo,
           amountOfPalletsUsed: (!!setAmountOfPalletsUsed ? setAmountOfPalletsUsed : 0)
       };
       orderService.markOrderAsFullyPickedAPI(pickCompleteRequest)
       .then((resp: PickCompleteResponse) => {
            toast.info(`Order ${currOrder!.transRefCode} is complete`);
            navigate('/orders');
       })
       .catch((error: any) => {
           toast.error(error.message || error);
       });
    }, [navigate, getCurrentOrder]);

    const checkIfOrderComplete = useCallback((): void => {
        const order = getCurrentOrder();
        if (!order || !(order!.cartLines)) {
          return;
        }
        const incompleteLines = order.cartLines.find(product => ((product!.qty || 0) < (product!.qtyOrdered || 0)));
        if (!incompleteLines) {
            if (!!order.needsAmountOfPalletsUsed) {
                //ask user to enter amount of pallets used
                onShowModal();
            }
            else
            {
                completeOrder(order);
            }           
        };
    }, [getCurrentOrder, completeOrder]);

    
    const parseDecimal = useCallback((n: number) : number => {
        let decimalCount = countDecimals(n);
        if (decimalCount === 0) {
          return Math.floor(n);
        } else if (decimalCount > 3) {
          decimalCount = 3;
        }
        return parseFloat(n.toFixed(decimalCount));
    }, []);

    const onQtySuccessfullyChanged = useCallback((addedQty: number, replacedQty: number, row: Product) : void => {
        if (row.qty === undefined) {
          row.qty = 0;
        }
        if (row.qty < 0) {
          row.qty = 0;
        } else {
          row.qty = parseDecimal(row.qty);
        }
        row.qtyOrdered = parseDecimal(row.qtyOrdered || 0);
        if (addedQty !== 0) {
            row.qty += addedQty;
        } else {
            row.qty = replacedQty;
        }
        if (row.qty < 0) {
          row.qty = 0;
        }
        if (row.qty > row.qtyOrdered) {
          row.qty = row.qtyOrdered;
        }
        const product = {...row};
        const products: Product[] = [];
        products.push(product);
        addToCart(products, true);
    }, [addToCart, parseDecimal]);

    const isOverPicked = useCallback((stockCode: string | undefined, addedQty: number): boolean => {
        if (stockCode === undefined || addedQty <= 0) {
            return false;
        }
        const order = getCurrentOrder();
        if (!order || !(order!.cartLines)) {
          return false;
        }
        const foundProduct = order.cartLines.find(product => product.stockCode === stockCode);
        if (!foundProduct) {
            return false;
        }

        const orderedQty = foundProduct.qtyOrdered || 0;
        const currentQty = (foundProduct.qty || 0) + addedQty;
        return (currentQty > orderedQty);
    }, [getCurrentOrder]);

    const doFirstScan = useCallback((barcode: string): void => {
        scanObject.current = null; //reset
        
        const order = getCurrentOrder();
        if (!order) {
            setSearchTerm('');
            errorSound.play();
            toast.error('Order not found', { autoClose: 200 });
            return;
        }
        
        itemService.scanItemFirstAPI(barcode)
            .then((scanItemResponse: ScanItemResponse) => {
                const scanresp = showScanMessage(scanItemResponse);
                scanObject.current = {
                    ...scanItemResponse,
                    scanResponseStatus : scanresp,
                    companyNo: order.companyNo,
                    transNo: order.transNo
                };

                if (scanresp === 'error') {
                    return;
                }
            
                const foundCartLine = order!.cartLines!.find(x => x.stockCode === scanItemResponse.stockCode);
                if (!foundCartLine) {
                    setSearchTerm('');
                    errorSound.play();
                    toast.error('Current order does not contain this product', { autoClose: 1000 });
                    return;
                }
                if ((foundCartLine.qtyOrdered || 0) <= (foundCartLine.qty || 0)) {
                    setSearchTerm('');
                    errorSound.play();
                    toast.error('All products with the current barcode have been already scanned', { autoClose: 2000 });
                    return;
                }
                const addedQty: number = calculateAddedQty(scanItemResponse.scanFactor!, foundCartLine);
                if (isOverPicked(foundCartLine.stockCode, addedQty)){
                    toast.error('Scanned quantity exceeds ordered quantity', { autoClose: 2000 });
                    return;
                }
                scanObject.current = {
                    ...scanObject.current,
                    detailNo: foundCartLine.detailNo,
                    stockCode: foundCartLine.stockCode,
                    pickQty: addedQty
                };
                if (scanresp === 'scanbin') {
                    return;
                }
                const pickItemRequest : PickItemRequest = {
                    companyNo: order.companyNo,
                    transNo: order.transNo,
                    detailNo: foundCartLine.detailNo,
                    stockCode: foundCartLine.stockCode,
                    pickQty: addedQty,
                    serialNo: scanItemResponse.serialNo,
                    binNo: scanItemResponse.binNo,
                    locationNo: scanItemResponse.locationNo
                };
                itemService.markItemAsScannedAPI(pickItemRequest)
                    .then((pickItemResponse: PickItemResponse) => {
                        if (pickItemResponse) {
                            setSearchTerm('');
                            onQtySuccessfullyChanged(0, pickItemResponse.linePickQty || 0, foundCartLine);
                            successSound.play();
                            toast.success(`Added ${addedQty.toLocaleString(navigator.language, { maximumFractionDigits: 3 })}`, { autoClose: 200 });
                            checkIfOrderComplete();
                        } else {
                            setSearchTerm('');
                            errorSound.play();
                            toast.error(`Not Added ${addedQty.toLocaleString(navigator.language, { maximumFractionDigits: 3 })}`, { autoClose: 200 });
                        }
                    })
                    .catch((error: any) => {
                        setSearchTerm('');
                        toast.error(error.message || error);
                    });
            })
            .catch((error: any) => {
                toast.error(error.message || error);
            });
    }, [checkIfOrderComplete, getCurrentOrder, onQtySuccessfullyChanged, isOverPicked]);

    const doSecondScan = useCallback((binCode: string): void => {
        const order = getCurrentOrder();
        if (!order) {
            setSearchTerm('');
            errorSound.play();
            toast.error('Order not found', { autoClose: 200 });
            return;
        }

        const foundCartLine = order!.cartLines!.find(x => x.stockCode === scanObject.current?.stockCode);
        if (!foundCartLine) {
            setSearchTerm('');
            errorSound.play();
            toast.error('Current order does not contain this product', { autoClose: 1000 });
            return;
        }
        if ((foundCartLine.qtyOrdered || 0) <= (foundCartLine.qty || 0)) {
            setSearchTerm('');
            errorSound.play();
            toast.error('All products with the current barcode have been already scanned', { autoClose: 1000 });
            return;
        }
        
        itemService.scanItemSecondAPI(binCode, foundCartLine.stockCode!)
            .then((scanItemResponse: ScanItemResponse) => {
                const scanresp = showScanMessage(scanItemResponse, true);
                scanObject.current = {
                    ...scanObject.current,
                    ...scanItemResponse,
                    scanResponseStatus : scanresp
                };
                if (scanresp === 'error') {
                    return;
                }
            
                const addedQty: number = calculateAddedQty(scanItemResponse.scanFactor!, foundCartLine);
                if (isOverPicked(foundCartLine.stockCode, addedQty)){
                    toast.error('Scanned quantity exceeds ordered quantity', { autoClose: 2000 });
                    return;
                }
                scanObject.current = {
                    ...scanObject.current,
                    detailNo: foundCartLine.detailNo,
                    stockCode: foundCartLine.stockCode,
                    pickQty: addedQty
                };
                const pickItemRequest : PickItemRequest = {
                    companyNo: order.companyNo,
                    transNo: order.transNo,
                    detailNo: foundCartLine.detailNo,
                    stockCode: foundCartLine.stockCode,
                    pickQty: addedQty,
                    serialNo: scanItemResponse.serialNo,
                    binNo: scanItemResponse.binNo,
                    locationNo: scanItemResponse.locationNo
                };
                if (scanresp === 'scanbin') {
                    return;
                }
                itemService.markItemAsScannedAPI(pickItemRequest)
                    .then((pickItemResponse: PickItemResponse) => {
                        if (pickItemResponse) {
                            setSearchTerm('');
                            onQtySuccessfullyChanged(0, pickItemResponse.linePickQty || 0, foundCartLine);
                            successSound.play();
                            toast.success(`Added ${addedQty.toLocaleString(navigator.language, { maximumFractionDigits: 3 })}`, { autoClose: 200 });
                            checkIfOrderComplete();
                        } else {
                            setSearchTerm('');
                            errorSound.play();
                            toast.error(`Not Added ${addedQty.toLocaleString(navigator.language, { maximumFractionDigits: 3 })}`, { autoClose: 200 });
                        }
                    })
                    .catch((error: any) => {
                        setSearchTerm('');
                        toast.error(error.message || error);
                    });
            })
            .catch((error: any) => {
                toast.error(error.message || error);
            });
    }, [checkIfOrderComplete, getCurrentOrder, onQtySuccessfullyChanged, isOverPicked]);

    const doQtyScan = useCallback((enteredQty: number): void => {
        if (enteredQty <= 0) {
            return;
        }

        const order = getCurrentOrder();
        if (!order) {
            setSearchTerm('');
            errorSound.play();
            toast.error('Order not found', { autoClose: 1000 });
            return;
        }
        const foundCartLine = order!.cartLines!.find(x => x.stockCode === scanObject.current?.stockCode);
        if (!foundCartLine) {
            setSearchTerm('');
            errorSound.play();
            toast.error('Current order does not contain this product', { autoClose: 2000 });
            return;
        }
        if ((foundCartLine.qtyOrdered || 0) <= (foundCartLine.qty || 0)) {
            setSearchTerm('');
            errorSound.play();
            toast.error('All products with the current barcode have been already scanned', { autoClose: 2000 });
            return;
        }
        const newQty = (enteredQty - (scanObject.current?.pickQty || 0))
        if (newQty <= 0) {
            toast.error('New quantity must higher than previous quantity', { autoClose: 2000 });
            return;
        }
        const pickItemRequest : PickItemRequest = {
            ...scanObject.current,
            pickQty: newQty
        };
        itemService.markItemAsScannedAPI(pickItemRequest)
            .then((pickItemResponse: PickItemResponse) => {
                scanObject.current = null; //reset
                if (pickItemResponse) {
                    setSearchTerm('');
                    onQtySuccessfullyChanged(0, pickItemResponse.linePickQty || 0, foundCartLine);
                    successSound.play();
                    toast.success(`Quantity updated`, { autoClose: 200 });
                    setScanBarcodePlaceholder(scanBarcodePlaceholderMessage);
                    checkIfOrderComplete();
                } else {
                    setSearchTerm('');
                    errorSound.play();
                    toast.error(`Not updated`, { autoClose: 200 });
                }
            })
            .catch((error: any) => {
                scanObject.current = null; //reset
                setSearchTerm('');
                toast.error(error.message || error);
            });
    }, [checkIfOrderComplete, getCurrentOrder, onQtySuccessfullyChanged]);

    const getBarcodeQty = useCallback((barcode: string) : number => {
        if (!barcode || !barcode.trim() || isNaN(Number(barcode.trim()))) {
            return 0;
        }

        const digits = getUser()?.attributes?.qtydigits;
        if (!digits || !digits.trim() || isNaN(Number(digits.trim()))) {
            return 0;
        }

        let amountOfDigits = 0;
        if (digits) {
            amountOfDigits = parseInt(digits.trim(), 10);
        }

        if (barcode.trim().length <= amountOfDigits) {
            return parseInt(barcode.trim(), 10);
        }
        return 0;
    }, [getUser]);

    const onBarcodeScanned = useCallback((barcode: string): void => {
        const order = getCurrentOrder();
        if (order && order.pickComplete) {            
            toast.info('This order is fully picked.');
            return;
        }

        if (scanObject.current?.scanResponseStatus === 'scanbin' && isBarcodeBinCode(barcode)) {
            doSecondScan(barcode);
            return;
        }

        if (scanObject.current?.scanResponseStatus === 'success') {
            const enteredQty = getBarcodeQty(barcode);
            if (enteredQty > 0) {
                doQtyScan(enteredQty);
                return;
            }
        }
        doFirstScan(barcode);
        
    }, [scanObject, doFirstScan, doSecondScan, doQtyScan, getCurrentOrder, getBarcodeQty]);
    
    const onKeyPressed = useCallback((event: any)  => {
        if (event.target && event.target instanceof HTMLInputElement &&
            event.target.name === searchBoxInputRef.current!.name) {
                return;
        }
        const keyPressed = event.key ? event.key : '';
        if (keyPressed === '') {
            return;
        }
        if (keyPressed.toLowerCase() === 'tab' || keyPressed.toLowerCase() === 'enter') {
            event.preventDefault();
            setSearchTerm((oldState) => {
                const newState = oldState;
                onBarcodeScanned(newState);
                return newState;
            });


        } else if (keyPressed.length === 1) {
            setSearchTerm((oldState) => {
                const newState = oldState + keyPressed;
                return newState;
            });
        }
    }, [onBarcodeScanned]);

    const lockUnlock = useCallback((toLock: boolean): Promise<GenericResponse> => {
        return new Promise<GenericResponse>((resolve, reject) => {
            const order = getCurrentOrder();
            if (order) {
                const lockRequest: LockUnlockRequest = {
                    transNo: order.transNo,
                    toLock
                }
                orderService.lockUnlockOrderAPI(lockRequest)
                .then((lockResponse: LockUnlockResponse) => {
                    if (!lockResponse) {
                        const resp: GenericResponse = {
                            success: false,
                            errorMessage: (toLock ? 'Couldn\'t acquire order lock.' : 'Couldn\'t acquire unlock.')
                        };
                        return resolve(resp);
                    } else if (!lockResponse.success) {
                        const resp: GenericResponse = {
                            success: false,
                            errorMessage: (toLock ? 'Current order is locked by another user.' : 'There was an error unlocking the order.')
                        };
                        return resolve(resp);
                    } else if (lockResponse.success) {
                        locked.current = !!toLock;
                        const resp: GenericResponse = {
                            success: true,
                            errorMessage: ''
                        };
                        return resolve(resp);
                    }
                })
                .catch((error: any) => {
                    return reject(error);
                });
            } else {
                return reject('Order not found');
            }
        });
    }, [getCurrentOrder]);

    const bindGrid = useCallback(() => {
        const order = getCurrentOrder();
        if (order) {
            lockUnlock(true)
            .then((resp: GenericResponse) => {
                if (resp.success) {
                    if (order.pickComplete) {
                        toast.info('This order is fully picked.');
                    } else {
                        checkIfOrderComplete();
                    }
                } else {
                    toast.error(resp.errorMessage, { autoClose: 2000 });
                    return navigate('/orders');
                }
            })
            .catch((error: any) => {
                toast.error(error.message || error);
            });
        }
    }, [getCurrentOrder, lockUnlock, navigate]);

    useEffect(() => {
        bindGrid();
        window.addEventListener('keydown', onKeyPressed);
        return () => {
            window.removeEventListener('keydown', onKeyPressed);
          };
    }, [onKeyPressed, bindGrid]);

    useEffect(() => {
        return () => {
            if (!!locked.current) {
                lockUnlock(false).then((resp: GenericResponse) => {
                    if (!resp.success) {
                        toast.error(resp.errorMessage, { autoClose: 2000 });
                    }
                })
                .catch((error: any) => {
                    toast.error(error.message || error);
                });
            }
        };
    }, [getCurrentOrder, lockUnlock]);

    // useKeyDown((event: any) => {
    //     onKeyPressed(event);
    // }, []);

   

    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const handleKeyUp = (event: any) => {
        if (event.key && event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            onSearchClicked();
        }
    };

    const onSearchClicked = () => {
        onBarcodeScanned(searchTerm);
    };

    
    
    const countDecimals = (n: number) : number => {
        if ((n as any) === null) {
          return 0;
        }
        if (Math.floor(n) === n) return 0;
        return n.toString().split('.')[1].length || 0;
    };
    const getPickedCssClassName = (product: Product) : string => {
        let pickedCssClassName = 'qty-badge badge';
        if (product.qtyOrdered === product.qty) {
            pickedCssClassName += ' bg-success';
        } else {
            pickedCssClassName += ' bg-secondary';
        }
        return pickedCssClassName;
    }
    const showScanMessage = (scanItemResponse: ScanItemResponse, isSecondScan: boolean = false) : ScanResponseStatusType => {
        let message = '';
        let status: ScanResponseStatusType = 'error';
        if (scanItemResponse) {
            switch (scanItemResponse.status) {
                case 1:
                    status = 'success';
                    message = '';
                    break;
                case 2:
                    message = 'Barcode not found';
                    break;
                case 3:
                    if (isSecondScan) {
                        message = 'Bin not found';    
                    } else {
                        message = 'Now scan Bin';
                        status = 'scanbin';
                    }
                    break;
                case 4:
                    message = 'Stock code not found in Bin';
                    break;
                case 5:
                    message = 'Bin not found';
                    break;
                case 6:
                    message = 'Bin was found with wrong stock code';
                    break;
                case 0:
                default:
                    message = 'Error occurred';
                    break;
            }
        }
        
        setSearchTerm('');
        if (status === 'success') {
            
            if (message) {
                toast.success(message, { autoClose: 2000 });    
            }
        } else if (status === 'error') {
            errorSound.play();
            toast.error(message, { autoClose: 2000 });
        } else if (status === 'scanbin') {
            questionSound.play();
            toast.info(message, { autoClose: 2000 });
        }

        if (status === 'success') {
            setScanBarcodePlaceholder(scanBarcodeOrQryPlaceholderMessage);
        }
        else {
            setScanBarcodePlaceholder(scanBarcodePlaceholderMessage);
        }
        return status;
    };
    
    const calculateAddedQty =(scanFactor: string, product: Product) : number => {
        let addedQty: number = 0;
        switch (scanFactor.toLowerCase()) {
            case "unit":
                addedQty = product.scanUnitFactor || 1;
                break;
            case "ctn":
                addedQty = product.scanCtnFactor || 1;
                break;
            case "inn":
                addedQty = product.scanInnerFactor || 1;
                break;
            case "serial":
                addedQty = product.scanSerialFactor || 1;
                break;
            case "bincode":
            default:
                addedQty = 1;
                break;
        }
        return addedQty;
    };

    const isBarcodeBinCode = (barcode: string) : boolean => {
        const binRegex = new RegExp(/[A-Za-z][A-Za-z12]-[A-Za-z]-\d-\d\d/);
        return binRegex.test(barcode);
    };

    const handlePalletsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPalletsUsed(event.target.value);
        event.preventDefault();
    };
    const onShowModal = () => {
        setPalletsUsed('');
        setModalOpen(true);
    };
    const onCloseModal = () => {
        setModalOpen(false);
        
        const order = getCurrentOrder();
        if (!order || !(order!.cartLines) || !palletsUsed) {
          toast.error('Order not found. Request has not been processed', { autoClose: 1000 });
          return;
        }
        const numPalletsUsed = parseInt(palletsUsed, 10);
        setPalletsUsed('');
        completeOrder(order, numPalletsUsed);
    };

    return (
        <>
            <div className="container-limited-width">
                <div className="row">
                    <div className="col-12 p-2">
                        <div className="input-group">
                            <input name="searchBox"
                                type="text" 
                                className="form-control search-box" 
                                placeholder={scanBarcodePlaceholder}
                                autoCapitalize="none"
                                autoComplete="off"
                                aria-describedby="btnGroupAddon" 
                                onKeyUp={handleKeyUp} 
                                onChange={handleSearchChange} 
                                value={searchTerm}
                                ref={searchBoxInputRef} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2 mt-2 pl-2 pr-2">
                        <ul className="list-group">
                            {getCurrentOrder() && getCurrentOrder()!.cartLines!.map((product, index) => {
                                return (
                                    <li key={product.stockNo}
                                        className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{product.stockDesc}</div>
                                            {product.stockCode}                           
                                        </div>
                                        <div className="d-flex align-self-center align-items-center">
                                                {
                                                    (product.qtyOrdered === product.qty) && 
                                                    (<span className="ch-icon-wrap">
                                                        <i className="fa fa-check ch-complete" aria-hidden="true"></i>
                                                    </span>)
                                                }
                                            <span className={getPickedCssClassName(product)}>
                                                {product.qty!.toLocaleString(navigator.language, { maximumFractionDigits: 3 })}
                                                &nbsp;/&nbsp;
                                                {product.qtyOrdered!.toLocaleString(navigator.language, { maximumFractionDigits: 3 })}
                                            </span>
                                            <div className="d-flex pickunit">{product.pickUnit}</div>                                    
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <Modal
                show={modalOpen}
                onHide={onCloseModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Enter number of pallets used</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input name="pallets"
                        type="number"
                        min="1"
                        className="form-control search-box" 
                        placeholder="Number of pallets used" 
                        autoCapitalize="none"
                        value={palletsUsed}
                        onChange={handlePalletsChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={onCloseModal}>OK</Button>
                </Modal.Footer>
            </Modal>
      </>
    );
}

export default OrderPage;