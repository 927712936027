import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./PutAwayPage.scss";
import * as itemService from "../../Services/Http/ItemService";
import { toast } from 'react-toastify';
import { BlockerFunction, useBlocker, useNavigate } from 'react-router';
import { ScanItemResponse } from '../../Models/ScanItemResponse';
import { PutAwayRequest } from '../../Models/PutAwayRequest';
import { PutAwayItem } from '../../Models/PutAwayItem';
import { Button, Modal } from 'react-bootstrap';
import { ChangeStatusResponse } from '../../Models/ChangeStatusResponse';
interface Props {}

const successSound = new Audio();
successSound.src = '/assets/sounds/success.mp3';
successSound.load();

const errorSound = new Audio();
errorSound.src = '/assets/sounds/error.mp3';
errorSound.load();

const questionSound = new Audio();
questionSound.src = '/assets/sounds/question.mp3';
questionSound.load();

const PutAwayPage = (props: Props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const defaultSearchPlaceHolderValue = 'Scan destination bin';
    const secondarySearchPlaceHolderValue = 'Now scan pallet';
    const [searchPlaceHolder, setSearchPlaceHolder] = useState(defaultSearchPlaceHolderValue);
    const firstScanResult = useRef<ScanItemResponse | null>(null);
    const searchBoxInputRef = useRef<HTMLInputElement>(null);
    const [items, setItems] = useState<ScanItemResponse[] | null>(null);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const doFirstScan = useCallback((barcode: string): void => {
        if (!barcode || barcode.trim() === '') {
            setSearchTerm('');
            setSearchPlaceHolder(defaultSearchPlaceHolderValue);
            errorSound.play();
            toast.error('Bin barcode must have a value', { autoClose: 200 });
            return;
        }
        firstScanResult.current = null;

        itemService.scanItemFirstAPI(barcode.trim())
            .then((scanItemResponse: ScanItemResponse) => {
                if (!scanItemResponse || !scanItemResponse.binNo) {
                    setSearchTerm('');
                    setSearchPlaceHolder(defaultSearchPlaceHolderValue);
                    errorSound.play();
                    toast.error('Bin not found', { autoClose: 200 });
                    return;
                }

                firstScanResult.current = scanItemResponse;
                setSearchTerm('');
                setSearchPlaceHolder(secondarySearchPlaceHolderValue);
                questionSound.play();
                toast.info(secondarySearchPlaceHolderValue, { autoClose: 200 });
            })
            .catch((error: any) => {
                toast.error(error.message || error);
            });
    }, []);    

    const doSecondScan = useCallback((palletBarcode: string, firstScanItemResponse: ScanItemResponse): void => {
        if (!palletBarcode || palletBarcode.trim() === '') {
            setSearchTerm('');
            setSearchPlaceHolder(secondarySearchPlaceHolderValue);
            errorSound.play();
            toast.error('Pallet barcode must have a value', { autoClose: 200 });
            return;
        }
        itemService.scanItemFirstAPI(palletBarcode.trim())
            .then((scanItemResponse: ScanItemResponse) => {
                if (!scanItemResponse || !scanItemResponse.stockNo || !scanItemResponse.serialNo) {
                    setSearchTerm('');
                    setSearchPlaceHolder(defaultSearchPlaceHolderValue);
                    errorSound.play();
                    toast.error('Pallet not found', { autoClose: 200 });
                    setSubmitDisabled(false);
                    return;
                }
                firstScanResult.current = null;
                let currItems = items;
                if (!currItems) {
                    currItems = [];
                }
                const addItem : ScanItemResponse = {...scanItemResponse};
                addItem.binNo = firstScanItemResponse.binNo;
                currItems.push(addItem);
                setItems([...currItems]);

                setSearchTerm('');
                setSearchPlaceHolder(defaultSearchPlaceHolderValue);
                successSound.play();
                toast.success('Added', { autoClose: 200 });
            })
            .catch((error: any) => {
                toast.error(error.message || error);
            });
    }, [items]);

    const onBarcodeScanned = useCallback((barcode: string): void => {
        const hasFirstScan = !!firstScanResult.current;
        if (hasFirstScan && !isBarcodeBinCode(barcode)) {
            const copyFirstScanResult = {...firstScanResult.current};
            doSecondScan(barcode, copyFirstScanResult);
        } else {
            doFirstScan(barcode);
        }
    }, [firstScanResult, doFirstScan, doSecondScan]);

    const onKeyPressed = useCallback((event: any)  => {
        // if (event.target && event.target instanceof HTMLInputElement &&
        //     event.target.name === searchBoxInputRef.current!.name) {
        //         return;
        // }
        if (event.target && event.target instanceof HTMLInputElement) {
            return;
        }
        const keyPressed = event.key ? event.key : '';
        if (keyPressed === '') {
            return;
        }
        if (keyPressed.toLowerCase() === 'tab' || keyPressed.toLowerCase() === 'enter') {
            event.preventDefault();
            setSearchTerm((oldState) => {
                const newState = oldState;
                onBarcodeScanned(newState);
                return newState;
            });


        } else if (keyPressed.length === 1) {
            setSearchTerm((oldState) => {
                const newState = oldState + keyPressed;
                return newState;
            });
        }
    }, [onBarcodeScanned]);

    useEffect(() => {
        window.addEventListener('keydown', onKeyPressed);
        return () => {
            window.removeEventListener('keydown', onKeyPressed);
          };
    }, [onKeyPressed]);

    useEffect(() => {
        const beforeUnload = (e: BeforeUnloadEvent) => {
            const hasUnsavedChanges = (!!items && items.length > 0);
            if (!hasUnsavedChanges) {
                return;
            } else if (!window.confirm('Current order has not been fully scanned. Leave page ?')) {
                e.preventDefault();
            }
        }
        window.addEventListener('beforeunload', beforeUnload);
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [items]);
    
    // useKeyDown((event: any) => {
    //     onKeyPressed(event);
    // }, []);
    //let actionData = useActionData() as { ok: boolean } | undefined;
    let shouldBlock = useCallback<BlockerFunction>(
        ({ currentLocation, nextLocation }) =>
        !!items && items.length > 0 && currentLocation.pathname !== nextLocation.pathname,
        [items]
      );
    let blocker = useBlocker(shouldBlock);
    
    // Reset the blocker if the user cleans the form
    useEffect(() => {
        if (blocker.state === 'blocked' && (!items || items?.length === 0)) {
            blocker.reset();
        } else if (blocker.state === 'blocked' && !!items && items?.length > 0) {
            //show modal depending on blocker status
            setModalOpen(true);
        } else if (blocker.state !== 'blocked') {
            setModalOpen(false);
        }
    }, [blocker, items]);
    
    const submitPutAway = useCallback((): void => { 
        if (!items || items.length === 0) {
            toast.error('List is empty and was not submitted', { autoClose: 1000 });
            setSubmitDisabled(false);
            return;
        }
        const copyItems = [...items];
        setItems([]);
        const itemsToAdd = copyItems.map(x => {
            const addItem: PutAwayItem = {
                binNo: x.binNo,
                serialNo: x.serialNo,
                stockNo: x.stockNo
            };
            return addItem;
        });

        const putAwayRequest : PutAwayRequest = {
            items: itemsToAdd
        };
        itemService.putAwayAPI(putAwayRequest)
        .then((putAwayResponse: ChangeStatusResponse) => {
            if (putAwayResponse) {
                if (putAwayResponse.errorMessage) {
                    toast.error(putAwayResponse.errorMessage, { autoClose: 1000 });    
                } else if (putAwayResponse.transRefCode) {
                    toast.success(`Submitted successfully, TransRefCode: ${putAwayResponse.transRefCode}`, { autoClose: 2000 });
                    setSubmitDisabled(false);    
                    navigate('/menu');
                }
            } else {
                toast.error('There was an error submitting', { autoClose: 1000 });    
                setSubmitDisabled(false);
            }
        })
        .catch((error: any) => {
            toast.error(error.message || error);
            setSubmitDisabled(false);
        });
    }, 
    [items, navigate]);

    


    
    
    
    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const handleKeyUp = (event: any) => {
        if (event.key && event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            onSearchClicked();
        }
    };

    const onSearchClicked = () => {
        onBarcodeScanned(searchTerm);
    };

    const isBarcodeBinCode = (barcode: string) : boolean => {
        const binRegex = new RegExp(/[A-Za-z][A-Za-z12]-[A-Za-z]-\d-\d\d/);
        return binRegex.test(barcode);
    };

    const onSubmit = () => {
        setSubmitDisabled(true);
        submitPutAway();
        setTimeout(() => {
            setSubmitDisabled(false);
        }, 3000);
    };

    const onCloseModal = () => {
        setModalOpen(false);
    };

    let submitButtoneCssClass = 'btn btn-lg btn-success btn-block';
    if (!items) {
        submitButtoneCssClass = 'hidden';
    }
    return (
        <>
            <div className="container-limited-width">
                <div className="row">
                    <div className="col-12 p-2">
                        <div className="input-group">
                            <input name="searchBox"
                                type="text" 
                                className="form-control search-box" 
                                placeholder={searchPlaceHolder}
                                autoCapitalize="none" 
                                aria-describedby="btnGroupAddon" 
                                onKeyUp={handleKeyUp} 
                                onChange={handleSearchChange} 
                                value={searchTerm}
                                ref={searchBoxInputRef} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2 pl-2 pr-2">
                        <ul className="list-group">
                            {items && items.map((product, index) => {
                                return (
                                    <li key={product.stockNo}
                                        className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">{product.stockDesc}</div>
                                            {product.stockCode}                           
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2 mt-2 pl-2 pr-2">
                        <div className="d-grid">
                            <button disabled={submitDisabled} className={submitButtoneCssClass} type="button" onClick={onSubmit}>Submit</button>
                            {
                                submitDisabled &&
                                (
                                    <p className="text-center">Please wait...</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={modalOpen}
                onHide={onCloseModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>Items were scanned but not submitted !</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to leave without submitting ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={() => blocker.proceed?.()}>Leave</Button>
                    <Button variant='success' onClick={() => blocker.reset?.()}>Stay</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PutAwayPage;