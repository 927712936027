import axios from "axios";
import { ApiError } from "../../Models/ApiError";
import { HandleError } from "../../Helpers/ErrorHandler";
import { ScanItemResponse } from "../../Models/ScanItemResponse";
import { ScanItemFirstRequest } from "../../Models/ScanItemFirstRequest";
import { PickItemRequest } from "../../Models/PickItemRequest";
import { PickItemResponse } from "../../Models/PicktemResponse";
import { ScanItemSecondRequest } from "../../Models/ScantemSecondRequest";
import { PutAwayRequest } from "../../Models/PutAwayRequest";
import { ChangeStatusResponse } from "../../Models/ChangeStatusResponse";

const API_URL = process.env.REACT_APP_API_URL;

export const markItemAsScannedAPI = async (model: PickItemRequest) : Promise<PickItemResponse> => {
    return new Promise<ScanItemResponse>((resolve, reject) => {
        axios.post<ScanItemResponse>(`${API_URL}item/pick`, model)
        .then(resp => {
          return resolve(resp.data);
        })
        .catch((error: any) => {
          const apiErr: ApiError = HandleError(error);
          return reject(apiErr);
        });
    });
};

export const scanItemFirstAPI = async (barcode: string) : Promise<ScanItemResponse> => {
  const model : ScanItemFirstRequest = {
    barcode
  };
  return new Promise<ScanItemResponse>((resolve, reject) => {
      axios.post<ScanItemResponse>(`${API_URL}item/scanitemfirst`, model)
      .then(resp => {
        return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};

export const scanItemSecondAPI = async (binCode: string, stockCode: string) : Promise<ScanItemResponse> => {
  const model : ScanItemSecondRequest = {
    binCode,
    stockCode
  }
  return new Promise<ScanItemResponse>((resolve, reject) => {
      axios.post<ScanItemResponse>(`${API_URL}item/scanitemsecond`, model)
      .then(resp => {
        return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};

export const putAwayAPI = async (model: PutAwayRequest) : Promise<ChangeStatusResponse> => {
  return new Promise<ChangeStatusResponse>((resolve, reject) => {
      axios.post<ChangeStatusResponse>(`${API_URL}item/putaway`, model)
      .then(resp => {
        return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};