import { createContext, useEffect, useState } from "react";
import { UserInfo } from "../Models/UserInfo";
import * as accountService from "../Services/Http/AccountService";
import React from "react";
import axios from "axios";
import { ApiError } from "../Models/ApiError";
import { AuthenticateRequest } from "../Models/AuthenticateRequest";

const ENV_PROVIDER = process.env.REACT_APP_PROVIDER;
const ENV_CALL_SOURCE = process.env.REACT_APP_CALL_SOURCE;
const ENV_CALL_VERSION = process.env.REACT_APP_VERSION;

type UserContextType = {
  getUser: () => UserInfo | null;
  login: (username: string, password: string) => Promise<UserInfo | null>;
  logout: () => void;
  refreshToken: (user: UserInfo | null) => Promise<string | null>;
  isAuthenticated: () => boolean;
  changeUserToken: (newToken: string) => void;
};

type Props = { children: React.ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({ children }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false);
  const userKey = `e_user_${ENV_PROVIDER}`;
  const uuidKey = `e_uuid_${ENV_PROVIDER}`;
  const [currentUser, setCurrentUser] = useState<UserInfo | null>(null);

  useEffect(() => {
    axios.defaults.headers.common["Call-Source"] = ENV_CALL_SOURCE;
    axios.defaults.headers.common["Call-Version"] = ENV_CALL_VERSION;
    // check if already logged in
    const user = getUserStorage();
    if (user) {
      setCurrentUser({...user});
      setCurrentUser((state) => {
        setUserStorage({...user});
        if (!isTokenRefreshing) {
          setIsTokenRefreshing(true);
          refreshToken(user)
            .then((userNo: string | null) => {
              setIsReady(true);
              setIsTokenRefreshing(false);
            })
            .catch((err: ApiError) => {
              setIsReady(true);
              setIsTokenRefreshing(false);
            });
        }
        return state;
      });
    } else {
      setIsReady(true);
    }
  }, []);

  const getUser = (): UserInfo | null => {
    return currentUser;
  };

  const changeUserToken = (newToken: string): void => {
    if (newToken) {
      const userInfo = currentUser;
      if (userInfo && userInfo.token !== newToken) {
        userInfo.token = newToken;
        setCurrentUser({...userInfo});
        setUserStorage({...userInfo});
      }
    }
  };
  
  const refreshToken = (user: UserInfo | null = null) : Promise<string | null> => {
    return new Promise<string | null>((resolve, reject) => {
      if (!user && !getUser()) {
        return reject();
      }
      //just call an empty refresh endpoint and it will return a new token in Set-Authorization header if needed. It will be taken care in response.interceptor.service.ts
      accountService.refreshTokenAPI()
        .then((userNo: string) => {
          //if (userNo instanceof ApiError)
          if (userNo)
            //nothing to do here
            return resolve(userNo);
        })
        .catch((err: ApiError) => {
            return reject(err);
        });
    });
  };
  
  const login = async (username: string, password: string): Promise<UserInfo | null> => {
    return new Promise<UserInfo | null>((resolve, reject) => {
      setCurrentUser(null);
      setUserStorage(null);
      const model: AuthenticateRequest = {
        username: username,
        password: password,
        uuid: getDeviceUUID()
      };
      accountService.loginAPI(model)
        .then((resp: UserInfo) => {
            if (resp && resp.token) {
              const user: UserInfo = resp;
              setCurrentUser(user);
              setUserStorage(user);
              return resolve(user);
            } else {
              setCurrentUser(null);
              setUserStorage(null);
              return resolve(null);
            }
        })
        .catch((err: ApiError) => {
            console.error(err?.message || err);
            setCurrentUser(null);
            setUserStorage(null);
            return reject(err);
        });
    });
  };

  const logout = () : void =>{
    setCurrentUser(null);
    setUserStorage(null);
  };

  const isAuthenticated = (): boolean => {
    return !!currentUser;
  };
  
  const setUserStorage = (user: UserInfo | null): void => {
    if (user) {
      localStorage.setItem(userKey, JSON.stringify(user));
      if (user.token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
      } else {
        axios.defaults.headers.common["Authorization"] = "";
      }
    } else {
      localStorage.removeItem(userKey);
      axios.defaults.headers.common["Authorization"] = "";
    }
  }

  const getUserStorage = (): UserInfo | null => {
    const userStr = localStorage.getItem(userKey);
    let user: UserInfo | null = null;
    if (userStr) {
      user = JSON.parse(userStr);
    }
    return user;
  }

  const getDeviceUUID = (): string => {
    var uuid = getUUIDStorage();
    if (!uuid) {
      uuid = setUUIDStorage();
    }
    return uuid;
  }

  const setUUIDStorage = (): string => {
    const uuid = generateUUID();
    localStorage.setItem(uuidKey, uuid);
    return uuid;
  }

  const getUUIDStorage = () : string | null=> {
    const str = localStorage.getItem(uuidKey);
    if (str) {
      return str;
    }
    return null;
  }

  const generateUUID = () : string => {
    let
        d = new Date().getTime(),
        d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        let r = Math.random() * 16;
        if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
        } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : ((r & 0x7) | 0x8)).toString(16);
    });
  }
 
  return (
    <UserContext.Provider
      value={{ login, getUser, logout, isAuthenticated, refreshToken, changeUserToken }}
    >
      {isReady ? children : 'Loading...'}
    </UserContext.Provider>
  );
};

export const useAuth = () => React.useContext(UserContext);
