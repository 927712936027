import { useEffect, useRef, useState } from 'react'
import "./OrderListPage.scss";
import * as orderService from "../../Services/Http/OrderService";
import { OrderList } from '../../Models/OrderList';
import { OrderBase } from '../../Models/OrderBase';
import { ApiError } from '../../Models/ApiError';
import { toast } from 'react-toastify';
import { useOrder } from '../../Context/useOrder';
import { Order } from '../../Models/Order';
import { useNavigate } from 'react-router';
import Moment from 'react-moment';
import { useKeyDown } from '../../Hooks/useKeyDown';

interface Props {}

const successSound = new Audio();
successSound.src = '/assets/sounds/success.mp3';
successSound.load();

const errorSound = new Audio();
errorSound.src = '/assets/sounds/error.mp3';
errorSound.load();

const OrderListPage = (props: Props) => {
    const { setCurrentOrder } = useOrder();
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState<OrderBase[]>([]);
    const navigate = useNavigate();
    const orders = useRef<OrderBase[]>([]);
    const searchBoxInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setCurrentOrder(null);
        bindGrid();
    }, [setCurrentOrder]);


    useKeyDown((event: any) => {
        onKeyPressed(event);
    }, []);

    const onOrderSelected = (event: any, selectedOrder: OrderBase) => {
        orderService.getOrderDetailsAPI(selectedOrder.transRefCode!)
        .then((order: Order) => {
            if (order) {
                if (order.transDueDate !== null) {
                    order.transDueDate = new Date(order.transDueDate!);
                } else {
                    order.transDueDate = new Date();
                }
                if (order.transDate != null) {
                    order.transDate = new Date(order.transDate);
                } else {
                    order.transDate = new Date();
                }
                setCurrentOrder(order);
                navigate('/order');
            }
        })
        .catch((error: any) => {
            toast.error(error.message || error);
        });
    };

    const onSearchChanged = (passedSearchTerm?: string) => {
        let locSearchTerm = '';
        if (passedSearchTerm !== undefined) {
            locSearchTerm = passedSearchTerm;
        } else if (searchTerm) {
            locSearchTerm = searchTerm;
        }

        if (locSearchTerm) {
            const aFilteredOrders = orders.current.filter(a => a.transRefCode!.toLowerCase().startsWith(locSearchTerm.toLowerCase()));
            setFilteredOrders([...aFilteredOrders]);
        }
        else {
            setFilteredOrders([...orders.current]);
        }
    };

    const onOrderScanned = (term? : string) => {
        let barcode = term || searchTerm;
        if (!barcode) {
            return;
        }
        barcode = barcode.toLowerCase().trim();
        const orderFound = orders.current.find(o => o.transRefCode!.toLowerCase() === barcode || o.orderNo!.toLowerCase() === barcode);
        if (orderFound) {
          successSound.play();
          toast.success('Order found', { autoClose: 200 });
          onOrderSelected(null, orderFound);
        } else {
          errorSound.play();
          toast.error('Order not found', { autoClose: 200 });
          setSearchTerm((oldState) => {
            const newState = '';
            onSearchChanged(newState);
            return newState;
          });
        }
    };

    const onKeyPressed = (event: any)  => {
        if (event.target && event.target instanceof HTMLInputElement &&
            event.target.name === searchBoxInputRef.current!.name) {
                return;
        }
        const keyPressed = event.key ? event.key : '';
        if (keyPressed === '') {
            return;
        }
        if (keyPressed.toLowerCase() === 'tab' || keyPressed.toLowerCase() === 'enter') {
            event.preventDefault();
            setSearchTerm((oldState) => {
                const newState = oldState;
                onOrderScanned(newState);
                return newState;
            });
        } else if (keyPressed.length === 1) {
            setSearchTerm((oldState) => {
                const newState = oldState + keyPressed;
                onSearchChanged(newState);
                return newState;
            });
        }
    };

    const bindGrid = () => {
        orderService.getExistingOrdersAPI()
        .then((resp: OrderList) => {
            if (resp) {
                orders.current = [...resp.result!];
                setFilteredOrders([...resp.result!]);
                //bindModal();
            } else {
                orders.current = [];
                setFilteredOrders([]);
            }
        })
        .catch((err: ApiError) => {
            //toast.error(err.message); //error already handled in error handler
        });
    };

   
    const handleSearchChange = (event: any) => {
        setSearchTerm(event.target.value);
    };
    const handleKeyUp = (event: any) => {
        if (event.key && event.key.toLowerCase() === 'enter') {
            event.preventDefault();
            onSearchClicked();
        } else {
            onSearchChanged();
        }
    };
    const onSearchClicked = () => {
        onOrderScanned();
    };
 
    const onClearSearch = () => {
        //since state doesn't update immediately, we'll use an updater function
        setSearchTerm((oldState) => {
            const newState = '';
            onSearchChanged(newState);
            return newState;
        });
    };
    
    return (
        <>
            <div className="container-limited-width">
                <div className="row">
                    <div className="col-12 filters p-2">
                        <div className="input-group">
                            <input name="searchBox"
                                    type="text" 
                                    className="form-control search-box" 
                                    placeholder="Scan order barcode or select an order" 
                                    autoCapitalize="none"
                                    autoComplete="off"
                                    aria-describedby="btnGroupAddon" 
                                    onKeyUp={handleKeyUp} 
                                    onChange={handleSearchChange} 
                                    value={searchTerm}
                                    ref={searchBoxInputRef} />
                            <div className="input-group-text search-btn" id="btnGroupAddon" title="Search" onClick={onSearchClicked}>
                                <i className="fa fa-search"></i>
                            </div>
                            <div className="input-group-text search-btn" id="btnGroupAddon" title="Clear" onClick={onClearSearch}>
                                <i className="fa fa-times-circle-o"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <ul className="list-group orders-list">
                        {filteredOrders.map((order, index) => {
                               return (
                                    <li key={order.orderNo}
                                        className="list-group-item d-flex justify-content-between align-items-center" 
                                        onClick={(e) => onOrderSelected(e, order)}>
                                        {order.transRefCode}                                        
                                        <div className="d-flex align-self-end align-items-center">
                                            {
                                                (order.pickComplete) && 
                                                (<span className="icon-wrap">
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                </span>)
                                            }
                                            {
                                                (order.orderQaTested) && 
                                                (<span className="icon-wrap">
                                                    <span className="fa-stack icon-stack">
                                                        <i className="fa fa-circle-thin fa-stack-2x icon-stack-2x"></i>
                                                        <span className="fa-stack-1x icon-stack-1x qa-checked">QA</span>
                                                    </span>
                                                </span>)
                                            }
                                            {
                                                (order.orderWrapped) && 
                                                (<span className="icon-wrap">
                                                    <i className="fa fa-gift"></i>
                                                </span>)
                                            }
                                            {
                                                (order.orderWasDispatched) &&
                                                (<span className="icon-wrap">
                                                    <i className="fa fa-paper-plane-o"></i>
                                                </span>)
                                            }
                                            {
                                                (order.isLocked) && 
                                                (<span className="icon-wrap">
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </span>)
                                            }
                                            <span className="badge bg-secondary badge-pill date-badge">
                                                <Moment date={order.transDueDate} format="ddd DD/MM/YYYY" />
                                            </span>
                                        </div>
                                    </li>
                                );
                            })
                        }
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OrderListPage;