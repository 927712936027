import { createContext, useEffect, useState } from "react";
import React from "react";
import { Order } from "../Models/Order";
import { Product } from "../Models/Product";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const ENV_PROVIDER = process.env.REACT_APP_PROVIDER;

type OrderContextType = {
  getCurrentOrder: () => Order | null;
  setCurrentOrder: (order: Order | null) => void;
  addToCart: (products: Product[], setTouched: boolean) => void;
  //currentOrderState: Order | null;
  //setCurrentOrderState: React.Dispatch<React.SetStateAction<Order | null>>;              
};

type Props = { children: React.ReactNode };

const OrderContext = createContext<OrderContextType>({} as OrderContextType);

export const OrderProvider = ({ children }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const orderKey = `e_order_${ENV_PROVIDER}`;
  const [currentOrderState, setCurrentOrderState] = useState<Order | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    // check if cart already saved
    const order = getOrderStorage();
    if (order) {
        setCurrentOrder(order);
    }
    setIsReady(true);
  }, []);

  useEffect(() => {
    setOrderStorage(currentOrderState);
  }, [currentOrderState]);

  const getCurrentOrder = (): Order | null => {
    return currentOrderState;
  };
  const setCurrentOrder = (order: Order | null): void => {
    if (order && order !== null) {
      setCurrentOrderState({...order});
    } else {
      setCurrentOrderState(null);    
    }
  }
  const setOrderStorage = (order: Order | null): void => {
    if (order) {
      localStorage.setItem(orderKey, JSON.stringify(order));
    } else {
      localStorage.removeItem(orderKey);
    }
  };
  const getOrderStorage = (): Order | null => {
    const orderJson = localStorage.getItem(orderKey);
    let order: Order | null = null;
    if (orderJson) {
      order = JSON.parse(orderJson) as Order;
      if (order) {
        if (order.transDueDate !== null) {
          order.transDueDate = new Date(order.transDueDate!);
        } else {
          order.transDueDate = new Date();
        }
        if (order.transDate != null) {
          order.transDate = new Date(order.transDate);
        } else {
          order.transDate = new Date();
        }
      }
    }
    return order;
  };
  const addToCart = (products: Product[], setTouched: boolean) : void => {
    let order = currentOrderState;
    if (!order) {
      toast.error('Please select an order from the list');
      navigate('/orders');
      return;
    }
    if (setTouched) {
      order.touched = true;
    }
    let cartLines: Product[] = [];
    if (order.cartLines && order.cartLines.length > 0) {
      cartLines = [...order.cartLines];
      //merge with existing products
      products.forEach((prod: Product) => {
        const found: Product = cartLines.find(p => p.key === prod.key)!;
        if (found) {
          found.qty = prod.qty;
        } else {
          cartLines.push(prod);
        }
      });
    } else {
      cartLines = products;
    }
    order.cartLines = cartLines;
    setCurrentOrder(order);
  }
  return (
    <OrderContext.Provider
      value={{ getCurrentOrder, setCurrentOrder, addToCart }}
    >
      {isReady ? children : null}
    </OrderContext.Provider>
  );
};

export const useOrder = () => React.useContext(OrderContext);
