import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../Context/useAuth";

type Props = { 
  children: React.ReactNode,
  checkLoginAttribute?: string | string[]
};
const ProtectedRoute = ({ children, checkLoginAttribute }: Props) => {
  const location = useLocation();
  const { isAuthenticated, getUser } = useAuth();

  const isAllowed = (loginAttribute?: string | string[]) : boolean => {
    if (loginAttribute === undefined) {
      return true;
    }
    let attrs = [];
    if (!Array.isArray(loginAttribute)) {
      attrs = [loginAttribute];
    } else {
      attrs = loginAttribute;
    }
    for (let i = 0; i < attrs.length; i++) {
      const attr = attrs[i];
      if (getUser()?.attributes?.[attr] === 'true') {
        return true;
      }
    }
    return false;
  };

  if (isAuthenticated() && isAllowed(checkLoginAttribute)) {
      return (
        <>{children}</>
      );
  } else {
    return (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }
};

export default ProtectedRoute;
