import React, { useEffect, useState } from 'react';
import "./Footer.scss";
import { useAuth } from '../../Context/useAuth';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useOrder } from '../../Context/useOrder';

function Footer() {
    const { isAuthenticated } = useAuth();
    const [showFooter, setShowFooter] = useState(true);
    const { getCurrentOrder } = useOrder();
    const location = useLocation();
    const currentRoute = location.pathname.toLowerCase();
    const navigate = useNavigate();
    
    let orderListCssClass = 'd-flex flex-column';
    if (currentRoute?.indexOf('/orders') > -1) {
        orderListCssClass += ' link-active';
    }
    let currentOrderCssClass = 'd-flex flex-column';
    if (currentRoute?.indexOf('/order') > -1 && currentRoute?.indexOf('/orders') === -1) {
        currentOrderCssClass += ' link-active';
    }
    if (!getCurrentOrder()) {
        currentOrderCssClass += ' grayed-out';
    }
    let homeCssClass = 'd-flex flex-column';
    if (currentRoute?.indexOf('/menu') > -1) {
        homeCssClass += ' link-active';
    }
    let putAwayCssClass = 'd-flex flex-column';
    if (currentRoute?.indexOf('/putaway') > -1) {
        putAwayCssClass += ' link-active';
    }
    useEffect(() => {
        const isShowFooter = (currentRoute.indexOf('/login') === -1 && currentRoute !== '/logout');
        setShowFooter(isShowFooter);
    }, [currentRoute, showFooter]);
    const onCurrentOrderClick = () => {
        const order = getCurrentOrder();
        if (order && order.transRefCode) {
            navigate('/order');
        }
    }
  return (
    <>
    {showFooter 
        ?
        (
            <footer className="d-lg-none footer mt-5 fixed-bottom">
                <div className="container-fluid d-flex justify-content-around bottom-nav pt-2">
                    {isAuthenticated() ? 
                    (
                        (
                            <>
                            <NavLink className={homeCssClass} title="Home" to='/menu'>
                                <i className="fa fa-home footer-icon" aria-hidden="true"></i>
                                <span>Home</span>
                            </NavLink>
                            {
                                currentRoute?.indexOf('/order') > -1 &&
                                (
                                    <>
                                        <NavLink className={orderListCssClass} title="Order List" to='/orders'>
                                            <i className="fa fa-th-list footer-icon" aria-hidden="true"></i>
                                            <span>Order List</span>
                                        </NavLink>
                                        {
                                            currentRoute === '/order' && (
                                                <a className={currentOrderCssClass} title="Current Order" onClick={onCurrentOrderClick}>
                                                    <i className="fa fa-hand-paper-o footer-icon" aria-hidden="true"></i>
                                                    <span>Current Order</span>
                                                </a>                                     
                                            )
                                        }
                                    </>
                                )
                            }
                            {
                                currentRoute?.indexOf('/putaway') > -1 && (
                                    <NavLink className={putAwayCssClass} title="Put Away" to='/putaway'>
                                        <i className="fa fa-archive footer-icon" aria-hidden="true"></i>
                                        <span>Put Away</span>
                                    </NavLink>
                                )
                            }
                            </>
                        ) 
                    ) 
                    : 
                    ("")
                    }
                </div>
            </footer>
        )
        :
        ("")
    }
    </>
  );
}

export default Footer;