import axios from "axios";
import { toast } from "react-toastify";
import { ApiError } from "../Models/ApiError";
import { router } from "../Routes/Routes";

export const HandleError = (error: any): ApiError => {
    const resultErr: ApiError = {
        message: error.message,
        status: error?.response?.status,
        code: error?.code
    };
    if (axios.isAxiosError(error) && error.response) {
        var err = error.response;
        if (Array.isArray(err?.data.errors)) {
            for (let val of err?.data.errors) {
                toast.warning(val.description);
            }
        } else if (typeof err?.data.errors === "object") {
            for (let e in err?.data.errors) {
                toast.warning(err.data.errors[e][0]);
            }
        } else if (err?.data) {
            toast.warning(err.data);
        } else if (err?.status === 401) {
            toast.dismiss();
            toast.warning("Please login");
            router.navigate('/login');
        } else if (err?.status === 403) {
            //wrong username or password
        } else if (err) {
            toast.warning(err?.data);
        }
    } else if (error?.code?.toUpperCase() === "ERR_NETWORK") {
        toast.dismiss()
        toast.error("Network Error!");
        router.navigate('/login');
    }
    return resultErr;
};
