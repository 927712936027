import axios from "axios";
import { ApiError } from "../../Models/ApiError";
import { HandleError } from "../../Helpers/ErrorHandler";
import { OrderList } from "../../Models/OrderList";
import { Order } from "../../Models/Order";
import { PickCompleteRequest } from "../../Models/PickCompleteRequest";
import { PickCompleteResponse } from "../../Models/PickCompleteResponse";
import { LockUnlockRequest } from "../../Models/LockUnlockRequest";
import { LockUnlockResponse } from "../../Models/LockUnlockResponse";
import { ChangeStatusRequest } from "../../Models/ChangeStatusRequest";
import { ChangeStatusResponse } from "../../Models/ChangeStatusResponse";

const API_URL = process.env.REACT_APP_API_URL;

export const getExistingOrdersAPI = async () : Promise<OrderList> => {
  return new Promise<OrderList>((resolve, reject) => {
    axios.get<OrderList>(`${API_URL}order`)
    .then(resp => {
      return resolve(resp.data);
    })
    .catch((error: any) => {
      const apiErr: ApiError = HandleError(error);
      return reject(apiErr);
    });
  });
};
export const getExistingOrdersForQaAPI = async () : Promise<OrderList> => {
  return new Promise<OrderList>((resolve, reject) => {
    axios.get<OrderList>(`${API_URL}order/qa`)
    .then(resp => {
      return resolve(resp.data);
    })
    .catch((error: any) => {
      const apiErr: ApiError = HandleError(error);
      return reject(apiErr);
    });
  });
};
export const getExistingOrdersForWrapAPI = async () : Promise<OrderList> => {
  return new Promise<OrderList>((resolve, reject) => {
    axios.get<OrderList>(`${API_URL}order/wrap`)
    .then(resp => {
      return resolve(resp.data);
    })
    .catch((error: any) => {
      const apiErr: ApiError = HandleError(error);
      return reject(apiErr);
    });
  });
};
export const getExistingOrdersForDispatchAPI = async () : Promise<OrderList> => {
  return new Promise<OrderList>((resolve, reject) => {
    axios.get<OrderList>(`${API_URL}order/dispatch`)
    .then(resp => {
      return resolve(resp.data);
    })
    .catch((error: any) => {
      const apiErr: ApiError = HandleError(error);
      return reject(apiErr);
    });
  });
};
export const getOrderDetailsAPI = async (transRefCode: string) : Promise<Order> => {
  return new Promise<Order>((resolve, reject) => {
    axios.get<Order>(`${API_URL}order/${transRefCode}`)
      .then(resp => {
        return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr : ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};
export const markOrderAsFullyPickedAPI = async (model: PickCompleteRequest) : Promise<PickCompleteResponse> => {
  return new Promise<PickCompleteResponse>((resolve, reject) => {
      axios.post<PickCompleteResponse>(`${API_URL}order/pickcomplete`, model)
      .then(resp => {
      return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};
export const setOrderQAStatusAPI = async (model: ChangeStatusRequest) : Promise<ChangeStatusResponse> => {
  return new Promise<ChangeStatusResponse>((resolve, reject) => {
      axios.post<ChangeStatusResponse>(`${API_URL}order/qastatus`, model)
      .then(resp => {
      return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};
export const setOrderWrapStatusAPI = async (model: ChangeStatusRequest) : Promise<ChangeStatusResponse> => {
  return new Promise<ChangeStatusResponse>((resolve, reject) => {
      axios.post<ChangeStatusResponse>(`${API_URL}order/wrapstatus`, model)
      .then(resp => {
      return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};
export const setOrderDispatchStatusAPI = async (model: ChangeStatusRequest) : Promise<ChangeStatusResponse> => {
  return new Promise<ChangeStatusResponse>((resolve, reject) => {
      axios.post<ChangeStatusResponse>(`${API_URL}order/dispatchstatus`, model)
      .then(resp => {
      return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};
export const lockUnlockOrderAPI = async (model: LockUnlockRequest) : Promise<LockUnlockResponse> => {
  return new Promise<LockUnlockResponse>((resolve, reject) => {
      axios.post<LockUnlockResponse>(`${API_URL}order/lockunlock`, model)
      .then(resp => {
      return resolve(resp.data);
      })
      .catch((error: any) => {
        const apiErr: ApiError = HandleError(error);
        return reject(apiErr);
      });
  });
};